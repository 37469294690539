import { useEffect, useMemo, useReducer } from 'react';
import { useGetExchangeRates } from '../../../../api/exchangeRates';
import { ActionsType, ExchangeRatesStateType } from '../types';
import { ExchangeRatesContext } from './exchange-rates-context';

const initialState = {
  exchangeRates: [],
  loading: true,
  error: null,
};

function reducer(state: ExchangeRatesStateType, action: ActionsType): ExchangeRatesStateType {
  switch (action.type) {
    case 'FETCH_SUCCESS':
      return {
        ...state,
        exchangeRates: action.payload,
        loading: false,
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
};

export function ExchangeRatesProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { exchangeRates, exchangeRatesLoading, exchangeRatesError } = useGetExchangeRates({
    order: 'desc',
    orderBy: 'bank',
    filters: {
      banks: ['MAIB', 'BNM'],
      rateDate: new Date(),
    },
  });

  useEffect(() => {
    if (!exchangeRatesLoading) {
      if (exchangeRatesError) {
        dispatch({ type: 'FETCH_ERROR', payload: exchangeRatesError });
      } else {
        dispatch({ type: 'FETCH_SUCCESS', payload: exchangeRates });
      }
    }
  }, [exchangeRates, exchangeRatesLoading, exchangeRatesError]);

  const memoizedValue = useMemo(
    () => ({
      exchangeRates: state.exchangeRates,
      loading: state.loading,
      error: state.error,
    }),
    [state.exchangeRates, state.loading, state.error]
  );

  return (
    <ExchangeRatesContext.Provider value={memoizedValue}>{children}</ExchangeRatesContext.Provider>
  );
}
