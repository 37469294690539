import Iconify from 'src/components/iconify';
import { m } from 'framer-motion';
import { IconButton, MenuItem, Typography } from '@mui/material';
import { varHover } from '../../../components/animate';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import { useExchangeRates } from './context';

export default function CurrencyPopover() {
  const { exchangeRates, error, loading } = useExchangeRates();
  const popover = usePopover();
  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify icon="flat-color-icons:currency-exchange" sx={{ borderRadius: 0.65 }} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 250 }}>
        {exchangeRates.map((option) => (
          <MenuItem key={option.uid} selected={false} onClick={() => {}}>
            <Iconify
              icon={`flag:${option.currency.alphaCode2.toLowerCase()}-4x3`}
              sx={{ borderRadius: 0.65, width: 28 }}
            />

            {`(${option.bank}) ${option.buyingRate} / ${option.sellingRate}`}
          </MenuItem>
        ))}
        {error && <Typography>Ошибка загрузки курсов валют</Typography>}
        {loading && <Typography>...</Typography>}
      </CustomPopover>
    </>
  );
}
