import { useMemo } from 'react';
import useSWR from 'swr';
import { TCurrency, TExchangeRate, TExchangeRatesFilters } from '../types/exchangeRates';
import { endpoints, fetcher } from '../utils/axios';
import { fDate } from '../utils/format-time';

type GetCurrenciesProps = {
  limit?: number;
  offset?: number;
  order?: 'asc' | 'desc';
  orderBy?: string;
};

export function useGetCurrencies({ limit, offset, order, orderBy }: GetCurrenciesProps) {
  const params = new URLSearchParams();
  if (limit) {
    params.set('limit', String(limit));
  }

  if (offset) {
    params.set('offset', String(offset));
  }

  if (order && orderBy) {
    params.set('ordering', `${order === 'asc' ? '' : '-'}${orderBy}`);
  }

  const URL = `${endpoints.currencies.list}?${params.toString()}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  return useMemo(
    () => ({
      currencies: (data?.results as TCurrency[]) || [],
      total: data?.total || 0,
      currenciesLoading: isLoading,
      currenciesError: error,
      currenciesValidating: isValidating,
      currenciesEmpty: !isLoading && !data?.results.length,
    }),
    [data, error, isLoading, isValidating]
  );
}

type GetCurrencyProps = {
  id: number;
};

export function useGetCurrencyProps({ id }: GetCurrencyProps) {
  const URL = endpoints.currencies.get(id);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  return useMemo(
    () => ({
      currency: data,
      currencyLoading: isLoading,
      currencyError: error,
      currencyValidating: isValidating,
      currencyNotFound: !isLoading && !data,
    }),
    [data, error, isLoading, isValidating]
  );
}

type GetExchangeRatesProps = {
  limit?: number;
  offset?: number;
  order?: 'asc' | 'desc';
  orderBy?: string;
  filters: TExchangeRatesFilters;
};

export function useGetExchangeRates({
  limit,
  offset,
  order,
  orderBy,
  filters,
}: GetExchangeRatesProps) {
  const params = new URLSearchParams();
  if (limit) {
    params.set('limit', String(limit));
  }

  if (offset) {
    params.set('offset', String(offset));
  }

  if (order && orderBy) {
    params.set('ordering', `${order === 'asc' ? '' : '-'}${orderBy}`);
  }

  if (filters.banks) {
    filters.banks.forEach((bank) => params.append('banks', bank));
  }

  if (filters.currencies) {
    filters.currencies.forEach((currency) => params.append('currencies', currency));
  }

  if (filters.rateDate) {
    params.set('rate_date', fDate(filters.rateDate, 'yyyy-MM-dd'));
  }

  const URL = `${endpoints.exchangeRates.list}?${params.toString()}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  return useMemo(
    () => ({
      exchangeRates: (data?.results as TExchangeRate[]) || [],
      total: data?.total || 0,
      exchangeRatesLoading: isLoading,
      exchangeRatesError: error,
      exchangeRatesValidating: isValidating,
      exchangeRatesEmpty: !isLoading && !data?.results.length,
    }),
    [data, error, isLoading, isValidating]
  );
}

type GetExchangeRateProps = {
  uid: string;
};

export function useGetExchangeRate({ uid }: GetExchangeRateProps) {
  const URL = endpoints.exchangeRates.get(uid);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  return useMemo(
    () => ({
      exchangeRate: data,
      exchangeRateLoading: isLoading,
      exchangeRateError: error,
      exchangeRateValidating: isValidating,
      exchangeRateNotFound: !isLoading && !data,
    }),
    [data, error, isLoading, isValidating]
  );
}
