import { format, getTime, formatDistanceToNow, intervalToDuration, isValid } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd-MM-yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd.MM.yyyy HH:mm';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function addDays(date: Date, delta: number) {
  date.setDate(date.getDate() + +delta);
  return date;
}

export function diffDays(start: Date | number, end: Date | number) {
  return intervalToDuration({ start, end }).days;
}

export function handleDates(data: any): void {
  if (!data || typeof data !== 'object') {
    return;
  }
  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (
      (key.endsWith('at') || key.endsWith('Date') || key.endsWith('Dt')) &&
      Object.prototype.hasOwnProperty.call(data, key) &&
      typeof value === 'string' &&
      isValid(new Date(value))
    ) {
      data[key] = new Date(value);
    } else if (typeof value === 'object') {
      handleDates(value);
    }
  });
}
