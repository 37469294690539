// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import { NavSectionMini } from 'src/components/nav-section';
//
import Link from '@mui/material/Link';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common';
import { RouterLink } from '../../routes/components';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useMockedUser();

  const navData = useNavData();

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Box sx={{ mx: 'auto', my: 2, width: '40px' }}>
          <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 64 64"
              fill="none"
            >
              <g clipPath="url(#clip0_1_3)">
                <path
                  d="M9.95646 62.1661L9.07031 53.4212C9.07031 52.6167 10.1165 53.329 11.4362 53.329C12.7455 53.329 13.8041 52.6167 13.8041 53.4212L13.0622 61.9498C13.0622 63.8121 9.95646 63.0537 9.95646 62.1661Z"
                  fill="url(#paint0_linear_1_3)"
                />
                <path
                  d="M6.15888 33.6976C-0.341314 30.8182 0.187634 26.2852 0.187634 26.2852C-0.632563 27.8851 1.11231 41.8428 7.88678 41.8428C10.9181 41.8428 13.4649 36.9135 6.15888 33.6976Z"
                  fill="url(#paint1_linear_1_3)"
                />
                <path
                  d="M6.15888 23.8361C-0.341314 20.9657 0.187634 16.4326 0.187634 16.4326C-0.632563 18.0288 1.11231 31.9902 7.88678 31.9902C10.9181 31.9902 13.4649 27.061 6.15888 23.8361Z"
                  fill="url(#paint2_linear_1_3)"
                />
                <path
                  d="M6.15888 14.2697C-0.341314 11.4115 0.187634 6.87012 0.187634 6.87012C-0.632563 8.4701 1.11231 22.4194 7.88678 22.4194C10.9181 22.4194 13.4649 17.503 6.15888 14.2697Z"
                  fill="url(#paint3_linear_1_3)"
                />
                <path
                  d="M15.1712 41.8428C21.9456 41.8428 23.682 27.8851 22.8703 26.2852C22.8703 26.2852 23.3953 30.8182 16.8912 33.6976C9.59304 36.9135 12.1313 41.8428 15.1712 41.8428Z"
                  fill="url(#paint4_linear_1_3)"
                />
                <path
                  d="M6.15888 43.7975C-0.341314 40.9188 0.187634 36.3857 0.187634 36.3857C-0.632563 37.9857 1.11231 51.9434 7.88678 51.9434C10.9181 51.9434 13.4649 47.0141 6.15888 43.7975Z"
                  fill="url(#paint5_linear_1_3)"
                />
                <path
                  d="M15.1712 51.9434C21.9456 51.9434 23.682 37.9857 22.8703 36.3857C22.8703 36.3857 23.3953 40.9188 16.8912 43.7975C9.59304 47.0141 12.1313 51.9434 15.1712 51.9434Z"
                  fill="url(#paint6_linear_1_3)"
                />
                <path
                  d="M15.1712 31.9902C21.9456 31.9902 23.682 18.0288 22.8703 16.4326C22.8703 16.4326 23.3953 20.9657 16.8912 23.8361C9.59304 27.061 12.1313 31.9902 15.1712 31.9902Z"
                  fill="url(#paint7_linear_1_3)"
                />
                <path
                  d="M15.1712 22.4194C21.9456 22.4194 23.682 8.4701 22.8703 6.87012C22.8703 6.87012 23.3953 11.4115 16.8912 14.2697C9.59304 17.503 12.1313 22.4194 15.1712 22.4194Z"
                  fill="url(#paint8_linear_1_3)"
                />
                <path
                  d="M11.9996 15.0155C19.1861 15.0155 11.6045 0 11.6045 0C10.6903 1.41247 4.81506 15.0155 11.9996 15.0155Z"
                  fill="url(#paint9_linear_1_3)"
                />
                <path
                  d="M31.4307 63.3387L35.7674 55.9013C36.1116 55.1852 34.8493 55.381 33.6647 54.8479C32.4618 54.3013 31.8199 53.223 31.4771 53.9519L28.5208 62.243C28.1401 63.0552 30.6249 65.0219 31.4307 63.3387Z"
                  fill="url(#paint10_linear_1_3)"
                />
                <path
                  d="M37.3101 34.86C32.6618 29.5647 35.0826 25.6729 35.0826 25.6729C33.6525 26.7852 29.2394 40.1681 35.3758 42.9636C38.1263 44.218 42.5565 40.801 37.3101 34.86Z"
                  fill="url(#paint11_linear_1_3)"
                />
                <path
                  d="M41.5528 25.9106C36.902 20.6275 39.3208 16.7363 39.3208 16.7363C37.8881 17.8448 33.4684 31.2232 39.6094 34.0277C42.3691 35.2776 46.7901 31.869 41.5528 25.9106Z"
                  fill="url(#paint12_linear_1_3)"
                />
                <path
                  d="M45.6647 17.2451C41.0008 11.9703 43.4326 8.0625 43.4326 8.0625C42.0038 9.17481 37.5874 22.5494 43.7304 25.3532C46.4809 26.5993 50.9065 23.1952 45.6647 17.2451Z"
                  fill="url(#paint13_linear_1_3)"
                />
                <path
                  d="M41.9877 45.9808C48.13 48.768 55.711 36.8398 55.6509 35.0439C55.6509 35.0439 54.1842 39.3735 47.046 39.2941C39.0452 39.2109 39.2326 44.7194 41.9877 45.9808Z"
                  fill="url(#paint14_linear_1_3)"
                />
                <path
                  d="M32.9734 44.0196C28.316 38.7275 30.7374 34.8408 30.7374 34.8408C29.3086 35.9487 24.8942 49.3277 31.0352 52.1143C33.7936 53.3687 38.2113 49.9607 32.9734 44.0196Z"
                  fill="url(#paint15_linear_1_3)"
                />
                <path
                  d="M37.6459 55.1307C43.7843 57.9307 51.3652 45.9897 51.3143 44.2021C51.3143 44.2021 49.843 48.5311 42.7094 48.4523C34.7047 48.3685 34.896 53.8808 37.6459 55.1307Z"
                  fill="url(#paint16_linear_1_3)"
                />
                <path
                  d="M46.2292 37.0365C52.3676 39.832 59.9433 27.8872 59.8924 26.1035C59.8924 26.1035 58.4218 30.4286 51.2881 30.3537C43.2782 30.2699 43.4741 35.7905 46.2292 37.0365Z"
                  fill="url(#paint17_linear_1_3)"
                />
                <path
                  d="M50.3402 28.3528C56.4832 31.1566 64.0504 19.2201 63.9995 17.4287C63.9995 17.4287 62.5243 21.7698 55.3992 21.6783C47.3938 21.5951 47.5897 27.1073 50.3402 28.3528Z"
                  fill="url(#paint18_linear_1_3)"
                />
                <path
                  d="M50.643 20.3288C57.1563 23.2952 56.7396 6.5459 56.7396 6.5459C55.303 7.46301 44.1298 17.3618 50.643 20.3288Z"
                  fill="url(#paint19_linear_1_3)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_1_3"
                  x1="9.66754"
                  y1="52.5587"
                  x2="16.4688"
                  y2="55.2133"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_1_3"
                  x1="1.37586"
                  y1="25.5438"
                  x2="14.8687"
                  y2="33.3897"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_1_3"
                  x1="1.37586"
                  y1="15.6912"
                  x2="14.8688"
                  y2="23.5372"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_1_3"
                  x1="1.37586"
                  y1="6.12914"
                  x2="14.8651"
                  y2="13.9771"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_1_3"
                  x1="13.5245"
                  y1="25.5438"
                  x2="27.0182"
                  y2="33.3912"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_1_3"
                  x1="1.37586"
                  y1="35.6444"
                  x2="14.8688"
                  y2="43.4903"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_1_3"
                  x1="13.5245"
                  y1="35.6444"
                  x2="27.0182"
                  y2="43.4918"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_1_3"
                  x1="13.5245"
                  y1="15.6912"
                  x2="27.0182"
                  y2="23.5387"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint8_linear_1_3"
                  x1="13.5245"
                  y1="6.12914"
                  x2="27.0146"
                  y2="13.9786"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint9_linear_1_3"
                  x1="9.31064"
                  y1="-0.715545"
                  x2="18.9764"
                  y2="2.81882"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint10_linear_1_3"
                  x1="29.4079"
                  y1="53.2165"
                  x2="38.412"
                  y2="58.5457"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint11_linear_1_3"
                  x1="33.1589"
                  y1="24.8366"
                  x2="44.2409"
                  y2="28.789"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint12_linear_1_3"
                  x1="37.3921"
                  y1="15.9001"
                  x2="48.4807"
                  y2="19.8581"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint13_linear_1_3"
                  x1="41.5098"
                  y1="7.22635"
                  x2="52.5936"
                  y2="11.181"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint14_linear_1_3"
                  x1="42.2084"
                  y1="34.5027"
                  x2="53.465"
                  y2="47.1514"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint15_linear_1_3"
                  x1="28.8157"
                  y1="34.0054"
                  x2="39.8998"
                  y2="37.9645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint16_linear_1_3"
                  x1="37.8698"
                  y1="43.6612"
                  x2="49.1205"
                  y2="56.3108"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint17_linear_1_3"
                  x1="46.448"
                  y1="25.5624"
                  x2="57.7021"
                  y2="38.2124"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint18_linear_1_3"
                  x1="50.5613"
                  y1="16.8879"
                  x2="61.809"
                  y2="29.5319"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <linearGradient
                  id="paint19_linear_1_3"
                  x1="49.6452"
                  y1="5.87231"
                  x2="60.6051"
                  y2="11.0983"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBBD3E" />
                  <stop offset="1" stopColor="#FADE36" />
                </linearGradient>
                <clipPath id="clip0_1_3">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </Box>

        <NavSectionMini
          data={navData}
          config={{
            currentRole: user?.role || 'admin',
          }}
        />
      </Stack>
    </Box>
  );
}
