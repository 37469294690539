import { useMemo } from 'react';
// components
import SvgColor from 'src/components/svg-color';
// routes
import { paths } from 'src/routes/paths';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  contract: icon('ic_contract'),
  finance: icon('ic_finance'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  return useMemo(
    () => [
      {
        subheader: t('management'),
        items: [
          {
            title: t('modules.users.label'),
            path: paths.erp.users.root,
            icon: ICONS.user,
            children: [
              { title: t('modules.users.employees.label'), path: paths.erp.users.employees.list },
              { title: t('modules.users.groups.label'), path: paths.erp.users.groups.list },
            ],
          },
          {
            title: t('modules.websiteManagement.label'),
            path: paths.erp.webSite.root,
            icon: ICONS.blog,
            children: [
              {
                title: t('modules.websiteManagement.news.label'),
                path: paths.erp.webSite.news.list,
              },
              {
                title: t('modules.websiteManagement.blog.label'),
                path: paths.erp.webSite.linkIn.list,
              },
              {
                title: t('modules.websiteManagement.communicationRequests.label'),
                path: paths.erp.webSite.communicationRequests.list,
              },
              {
                title: t('modules.websiteManagement.pricesManagement.label'),
                path: paths.erp.webSite.prices.list,
              },
            ],
          },
          {
            title: t('modules.storage.label'),
            path: paths.erp.storage.root,
            icon: ICONS.banking,
            children: [
              {
                title: t('modules.storage.organisations.label'),
                path: paths.erp.storage.organisations.list,
              },
              { title: t('modules.storage.crops.label'), path: paths.erp.storage.crops.list },
              {
                title: t('modules.storage.terminals.label'),
                path: paths.erp.storage.terminals.list,
              },
              {
                title: t('modules.storage.exchangeRates.label'),
                path: paths.erp.storage.exchangeRates.list,
              },
            ],
          },
          {
            title: t('modules.purchase.label'),
            path: paths.erp.purchase.root,
            icon: ICONS.invoice,
            children: [
              {
                title: t('modules.purchase.purchaseContracts.label'),
                path: paths.erp.purchase.contracts.list,
              },
              {
                title: t('modules.purchase.residues.label'),
                path: paths.erp.purchase.residues.list,
              },
            ],
          },
          {
            title: t('modules.sales.label'),
            path: paths.erp.sales.root,
            icon: ICONS.contract,
            children: [
              {
                title: t('modules.sales.exportContracts.label'),
                path: paths.erp.sales.salesContracts.list,
              },
            ],
          },
          {
            title: t('modules.logistics.label'),
            path: paths.erp.logistic.root,
            icon: ICONS.tour,
            children: [
              {
                title: t('modules.logistics.shipments.label'),
                path: paths.erp.logistic.shipments.trucks.list,
              },
            ],
          },
          {
            title: t('modules.finance.label'),
            path: paths.erp.finance.root,
            icon: ICONS.finance,
            children: [
              {
                title: t('modules.finance.purchasePayments.label'),
                path: paths.erp.finance.purchasePayments.list,
              },
            ],
          },
        ],
      },
    ],
    [t]
  );
}
