import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import ErpLayout from 'src/layouts/erp';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
// Users
const EmployeesPage = lazy(() => import('src/pages/dashboard/users/staff/list'));
const EmployeeEditPage = lazy(() => import('src/pages/dashboard/users/staff/edit'));
const EmployeeCreatePage = lazy(() => import('src/pages/dashboard/users/staff/create'));
// Groups
const GroupsPage = lazy(() => import('src/pages/dashboard/users/groups/list'));
const GroupEditPage = lazy(() => import('src/pages/dashboard/users/groups/edit'));
const GroupCreatePage = lazy(() => import('src/pages/dashboard/users/groups/create'));
// CommunicationRequest
const CommunicationRequestEditPage = lazy(
  () => import('src/pages/dashboard/webSite/communicationRequests/edit')
);
const CommunicationRequestsPage = lazy(
  () => import('src/pages/dashboard/webSite/communicationRequests/list')
);
// Link In Blog
const LinkInPage = lazy(() => import('src/pages/dashboard/webSite/linkInBlog/list'));
const LinkInEditPage = lazy(() => import('src/pages/dashboard/webSite/linkInBlog/edit'));
const LinkInCreatePage = lazy(() => import('src/pages/dashboard/webSite/linkInBlog/create'));

// Prices
const RegionsPage = lazy(() => import('src/pages/dashboard/webSite/prices/list'));
const RegionEditPage = lazy(() => import('src/pages/dashboard/webSite/prices/edit'));
const RegionCreatePage = lazy(() => import('src/pages/dashboard/webSite/prices/create'));
// News
const NewsPage = lazy(() => import('src/pages/dashboard/webSite/news/list'));
const NewsCreatePage = lazy(() => import('src/pages/dashboard/webSite/news/create'));
const NewsEditPage = lazy(() => import('src/pages/dashboard/webSite/news/edit'));
// Organisations
const OrganisationsPage = lazy(() => import('src/pages/dashboard/storage/organisations/list'));
const OrganisationsEditPage = lazy(() => import('src/pages/dashboard/storage/organisations/edit'));
const OrganisationsCreatePage = lazy(
  () => import('src/pages/dashboard/storage/organisations/create')
);
// Crops
const CropsPage = lazy(() => import('src/pages/dashboard/storage/crops/list'));
const CropsEditPage = lazy(() => import('src/pages/dashboard/storage/crops/edit'));
const CropsCreatePage = lazy(() => import('src/pages/dashboard/storage/crops/create'));
// Terminals
const TerminalsPage = lazy(() => import('src/pages/dashboard/storage/terminals/list'));
const TerminalEditPage = lazy(() => import('src/pages/dashboard/storage/terminals/edit'));
const TerminalCreatePage = lazy(() => import('src/pages/dashboard/storage/terminals/create'));
// Exchange Rates
const ExchangeRatesPage = lazy(() => import('src/pages/dashboard/storage/exchangeRates/list'));
// PurchaseContracts
const ContractsPage = lazy(() => import('src/pages/dashboard/purchase/contracts/list'));
const PurchaseContractsEditPage = lazy(() => import('src/pages/dashboard/purchase/contracts/edit'));
const PurchaseContractsCreatePage = lazy(
  () => import('src/pages/dashboard/purchase/contracts/create')
);
// Residues
const ResiduesPage = lazy(() => import('src/pages/dashboard/purchase/residues/list'));
const ResiduesEditPage = lazy(() => import('src/pages/dashboard/purchase/residues/edit'));
const ResiduesFractionEditPage = lazy(
  () => import('src/pages/dashboard/purchase/residues/fractions/edit')
);
const ResiduesTruckCreatePage = lazy(
  () => import('src/pages/dashboard/purchase/residues/fractions/fractionTruckCreate')
);
// SalesContracts
const SalesContractsPage = lazy(() => import('src/pages/dashboard/sales/contracts/list'));
const SalesFractionsEditPage = lazy(
  () => import('src/pages/dashboard/sales/contracts/fractions/edit')
);
const SalesContractMainInfoEditPage = lazy(
  () => import('src/pages/dashboard/sales/contracts/main-info')
);
const SalesContractFractionsEditPage = lazy(
  () => import('src/pages/dashboard/sales/contracts/fractions')
);
const SalesContractBargesListPage = lazy(
  () => import('src/pages/dashboard/sales/contracts/shipments/barges/list')
);
const SalesContractTrucksListPage = lazy(
  () => import('src/pages/dashboard/sales/contracts/shipments/trucks/list')
);
const SalesContractTruckCreatePage = lazy(
  () => import('src/pages/dashboard/sales/contracts/shipments/trucks/create')
);
const SalesContractFractionTruckCreatePage = lazy(
  () => import('src/pages/dashboard/sales/contracts/fractions/fractionTruckCreate')
);
const SalesContractBargeCreatePage = lazy(
  () => import('src/pages/dashboard/sales/contracts/shipments/barges/create')
);
const SalesContractWagonsListPage = lazy(
  () => import('src/pages/dashboard/sales/contracts/shipments/wagons/list')
);
const SalesContractCreatePage = lazy(() => import('src/pages/dashboard/sales/contracts/create'));
// Shipments
const TruckShipmentsPage = lazy(() => import('src/pages/dashboard/logistic/shipments/truck/list'));
const TruckShipmentsEditPage = lazy(
  () => import('src/pages/dashboard/logistic/shipments/truck/edit')
);
const TruckShipmentCreatePage = lazy(
  () => import('src/pages/dashboard/logistic/shipments/truck/create')
);
const BargeShipmentEditPage = lazy(
  () => import('src/pages/dashboard/logistic/shipments/barges/edit')
);
const BargePreviousShipmentsListPage = lazy(
  () => import('src/pages/dashboard/logistic/shipments/barges/previousShipments/list')
);
const BargeShipmentCreatePage = lazy(
  () => import('src/pages/dashboard/logistic/shipments/barges/create')
);
const BargesShipmentsPage = lazy(
  () => import('src/pages/dashboard/logistic/shipments/barges/list')
);
const WagonsShipmentsPage = lazy(
  () => import('src/pages/dashboard/logistic/shipments/wagons/list')
);
const WagonShipmentCreatePage = lazy(
  () => import('src/pages/dashboard/logistic/shipments/wagons/create')
);
const WagonShipmentEditPage = lazy(
  () => import('src/pages/dashboard/logistic/shipments/wagons/edit')
);
const WagonPreviousShipmentsListPage = lazy(
  () => import('src/pages/dashboard/logistic/shipments/wagons/previousShipments/list')
);
// PurchasePayments
const PurchasePaymentsPage = lazy(
  () => import('src/pages/dashboard/finance/purchasePayments/list')
);
const PurchasePaymentEditPage = lazy(
  () => import('src/pages/dashboard/finance/purchasePayments/edit')
);
const PurchasePaymentCreatePage = lazy(
  () => import('src/pages/dashboard/finance/purchasePayments/create')
);

const BargesEditPage = lazy(() => import('src/pages/dashboard/sales/barges/edit'));

// ----------------------------------------------------------------------

export const erpRoutes = [
  {
    path: 'erp',
    element: (
      <AuthGuard>
        <ErpLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </ErpLayout>
      </AuthGuard>
    ),
    children: [
      { element: <EmployeesPage />, index: true },
      {
        path: 'users/employees',
        children: [
          { element: <EmployeesPage />, index: true },
          { path: ':uid', element: <EmployeeEditPage /> },
          { path: 'create', element: <EmployeeCreatePage /> },
        ],
      },
      {
        path: 'users/groups',
        children: [
          { element: <GroupsPage />, index: true },
          { path: ':groupName', element: <GroupEditPage /> },
          { path: 'create', element: <GroupCreatePage /> },
        ],
      },
      {
        path: 'website/communication-requests',
        children: [
          { element: <CommunicationRequestsPage />, index: true },
          { path: ':uid', element: <CommunicationRequestEditPage /> },
        ],
      },
      {
        path: 'website/news',
        children: [
          { element: <NewsPage />, index: true },
          { path: ':uid', element: <NewsEditPage /> },
          { path: 'create', element: <NewsCreatePage /> },
        ],
      },
      {
        path: 'website/linkIn-blog',
        children: [
          { element: <LinkInPage />, index: true },
          { path: ':uid', element: <LinkInEditPage /> },
          { path: 'create', element: <LinkInCreatePage /> },
        ],
      },
      {
        path: 'website/prices',
        children: [
          { element: <RegionsPage />, index: true },
          { path: ':uid', element: <RegionEditPage /> },
          { path: 'create', element: <RegionCreatePage /> },
        ],
      },
      {
        path: 'storage/organisations',
        children: [
          { element: <OrganisationsPage />, index: true },
          { path: ':uid', element: <OrganisationsEditPage /> },
          { path: 'create', element: <OrganisationsCreatePage /> },
        ],
      },
      {
        path: 'storage/crops',
        children: [
          { element: <CropsPage />, index: true },
          { path: ':uid', element: <CropsEditPage /> },
          { path: 'create', element: <CropsCreatePage /> },
        ],
      },
      {
        path: 'storage/terminals',
        children: [
          { element: <TerminalsPage />, index: true },
          { path: ':uid', element: <TerminalEditPage /> },
          { path: 'create', element: <TerminalCreatePage /> },
        ],
      },
      {
        path: 'storage/exchange-rates',
        children: [{ element: <ExchangeRatesPage />, index: true }],
      },
      {
        path: 'purchase/purchase-contracts',
        children: [
          { element: <ContractsPage />, index: true },
          { path: ':uid', element: <PurchaseContractsEditPage /> },
          { path: 'create', element: <PurchaseContractsCreatePage /> },
        ],
      },
      {
        path: 'purchase/residues',
        children: [
          { element: <ResiduesPage />, index: true },
          {
            path: ':uid',
            element: <ResiduesEditPage />,
          },
          { path: ':uid/fractions/:fractionUid', element: <ResiduesFractionEditPage /> },
          {
            path: ':uid/fractions/:fractionUid/trucks/create',
            element: <ResiduesTruckCreatePage />,
          },
        ],
      },
      {
        path: 'sales/sales-contracts',
        children: [
          { element: <SalesContractsPage />, index: true },
          { path: ':uid/main-info', element: <SalesContractMainInfoEditPage /> },
          { path: ':uid/fractions', element: <SalesContractFractionsEditPage /> },
          { path: ':uid/shipments', element: <SalesContractBargesListPage /> },
          { path: ':uid/shipments/barges', element: <SalesContractBargesListPage /> },
          { path: ':uid/shipments/trucks', element: <SalesContractTrucksListPage /> },
          { path: ':uid/shipments/trucks/create', element: <SalesContractTruckCreatePage /> },
          { path: ':uid/shipments/barges/create', element: <SalesContractBargeCreatePage /> },
          { path: ':uid/shipments/wagons', element: <SalesContractWagonsListPage /> },
          { path: 'create', element: <SalesContractCreatePage /> },
          { path: ':uid/barge-shipments/:shipmentUid', element: <BargesEditPage /> },
          { path: ':uid/fractions/:fractionUid', element: <SalesFractionsEditPage /> },
          {
            path: ':uid/fractions/:fractionUid/trucks/create',
            element: <SalesContractFractionTruckCreatePage />,
          },
        ],
      },
      {
        path: 'logistic/trucks',
        children: [
          { element: <TruckShipmentsPage />, index: true },
          { path: ':uid', element: <TruckShipmentsEditPage /> },
          { path: 'create', element: <TruckShipmentCreatePage /> },
        ],
      },
      {
        path: 'logistic/barges',
        children: [
          { element: <BargesShipmentsPage />, index: true },
          { path: ':uid/main-info', element: <BargeShipmentEditPage /> },
          { path: ':uid/shipments', element: <BargePreviousShipmentsListPage /> },
          { path: 'create', element: <BargeShipmentCreatePage /> },
        ],
      },
      {
        path: 'logistic/wagons',
        children: [
          { element: <WagonsShipmentsPage />, index: true },
          { path: ':uid/main-info', element: <WagonShipmentEditPage /> },
          { path: ':uid/shipments', element: <WagonPreviousShipmentsListPage /> },
          { path: 'create', element: <WagonShipmentCreatePage /> },
        ],
      },
      {
        path: 'finance/purchase-payments',
        children: [
          { element: <PurchasePaymentsPage />, index: true },
          { path: ':uid', element: <PurchasePaymentEditPage /> },
          { path: 'create', element: <PurchasePaymentCreatePage /> },
        ],
      },
    ],
  },
];
