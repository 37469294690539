// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  ERP: '/erp',
  USERS: '/erp/users',
  WEBSITE: '/erp/website',
  STORAGE: '/erp/storage',
  PURCHASE: '/erp/purchase',
  SALES: '/erp/sales',
  LOGISTIC: '/erp/logistic',
  FINANCE: '/erp/finance',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // ERP
  erp: {
    root: ROOTS.ERP,
    users: {
      root: ROOTS.USERS,
      employees: {
        list: `${ROOTS.USERS}/employees`,
        get: (uid: string) => `${ROOTS.USERS}/employees/${uid}`,
        create: `${ROOTS.USERS}/employees/create`,
      },
      groups: {
        list: `${ROOTS.USERS}/groups`,
        get: (groupName: string) => `${ROOTS.USERS}/groups/${groupName}`,
        create: `${ROOTS.USERS}/groups/create`,
      },
    },
    webSite: {
      root: ROOTS.WEBSITE,
      news: {
        list: `${ROOTS.WEBSITE}/news`,
        get: (uid: string) => `${ROOTS.WEBSITE}/news/${uid}`,
        create: `${ROOTS.WEBSITE}/news/create`,
      },
      communicationRequests: {
        list: `${ROOTS.WEBSITE}/communication-requests`,
        get: (uid: string) => `${ROOTS.WEBSITE}/communication-requests/${uid}`,
      },
      prices: {
        list: `${ROOTS.WEBSITE}/prices`,
        get: (uid: string) => `${ROOTS.WEBSITE}/prices/${uid}`,
        create: `${ROOTS.WEBSITE}/prices/create`,
      },
      linkIn: {
        list: `${ROOTS.WEBSITE}/linkIn-blog`,
        get: (uid: string) => `${ROOTS.WEBSITE}/linkIn-blog/${uid}`,
        create: `${ROOTS.WEBSITE}/linkIn-blog/create`,
      },
    },
    storage: {
      root: ROOTS.STORAGE,
      organisations: {
        list: `${ROOTS.STORAGE}/organisations`,
        get: (uid: string) => `${ROOTS.STORAGE}/organisations/${uid}`,
        create: `${ROOTS.STORAGE}/organisations/create`,
      },
      crops: {
        list: `${ROOTS.STORAGE}/crops`,
        get: (uid: string) => `${ROOTS.STORAGE}/crops/${uid}`,
        create: `${ROOTS.STORAGE}/crops/create`,
      },
      terminals: {
        list: `${ROOTS.STORAGE}/terminals`,
        get: (uid: string) => `${ROOTS.STORAGE}/terminals/${uid}`,
        create: `${ROOTS.STORAGE}/terminals/create`,
      },
      exchangeRates: {
        list: `${ROOTS.STORAGE}/exchange-rates`,
        get: (uid: string) => `${ROOTS.STORAGE}/exchange-rates/${uid}`,
      },
    },
    purchase: {
      root: ROOTS.PURCHASE,
      contracts: {
        list: `${ROOTS.PURCHASE}/purchase-contracts`,
        get: (uid: string) => `${ROOTS.PURCHASE}/purchase-contracts/${uid}`,
        create: `${ROOTS.PURCHASE}/purchase-contracts/create`,
      },
      residues: {
        fractions: {
          get: (uid: string, fractionUid: string) =>
            `${ROOTS.PURCHASE}/residues/${uid}/fractions/${fractionUid}`,
          create: (residueUid: string) => `\`${ROOTS.PURCHASE}/residues/${residueUid}/fractions`,

          trucks: {
            create: (uid: string, fractionUid?: string) =>
              `${ROOTS.PURCHASE}/residues/${uid}/fractions/${fractionUid}/trucks/create`,
          },
        },
        list: `${ROOTS.PURCHASE}/residues`,
        get: (uid: string) => `${ROOTS.PURCHASE}/residues/${uid}`,
        create: `${ROOTS.PURCHASE}/residues/create`,
      },
    },
    sales: {
      root: ROOTS.SALES,
      salesContracts: {
        list: `${ROOTS.SALES}/sales-contracts`,
        get: (uid: string) => `${ROOTS.SALES}/sales-contracts/${uid}/shipments`,
        create: `${ROOTS.SALES}/sales-contracts/create`,
        bargeShipments: {
          get: (uid: string, shipmentUid: string) =>
            `${ROOTS.SALES}/sales-contracts/${uid}/barge-shipments/${shipmentUid}`,
        },
        mainInfo: {
          get: (uid: string) => `${ROOTS.SALES}/sales-contracts/${uid}/main-info`,
        },
        fractions: {
          get: (uid: string) => `${ROOTS.SALES}/sales-contracts/${uid}/fractions`,
        },
        fraction: {
          get: (uid: string, fractionUid?: string) =>
            `${ROOTS.SALES}/sales-contracts/${uid}/fractions/${fractionUid}`,
          trucks: {
            create: (uid: string, fractionUid?: string) =>
              `${ROOTS.SALES}/sales-contracts/${uid}/fractions/${fractionUid}/trucks/create`,
          },
        },
        shipments: {
          get: (uid: string) => `${ROOTS.SALES}/sales-contracts/${uid}/shipments/barges`,
          trucks: {
            list: (uid: string) => `${ROOTS.SALES}/sales-contracts/${uid}/shipments/trucks`,
            get: (uid: string, shipmentId: string) =>
              `${ROOTS.SALES}/sales-contracts/${uid}/shipments/trucks/${shipmentId}`,
            create: (uid: string) =>
              `${ROOTS.SALES}/sales-contracts/${uid}/shipments/trucks/create`,
          },
          wagons: {
            list: (uid: string) => `${ROOTS.SALES}/sales-contracts/${uid}/shipments/wagons`,
            get: (uid: string, shipmentId: string) =>
              `${ROOTS.SALES}/sales-contracts/${uid}/shipments/wagons/${shipmentId}`,
            create: (uid: string, shipmentId: string) =>
              `${ROOTS.SALES}/sales-contracts/${uid}/shipments/wagons/create`,
          },
          barges: {
            list: (uid: string) => `${ROOTS.SALES}/sales-contracts/${uid}/shipments/barges`,
            get: (uid: string, shipmentId: string) =>
              `${ROOTS.SALES}/sales-contracts/${uid}/shipments/barges/${shipmentId}`,
            create: (uid: string) =>
              `${ROOTS.SALES}/sales-contracts/${uid}/shipments/barges/create`,
          },
          addTruck: (uid: string) => `${ROOTS.SALES}/sales-contracts/${uid}/shipments/add-truck`,
          addWagon: (uid: string) => `${ROOTS.SALES}/sales-contracts/${uid}/shipments/add-wagon`,
          addBarge: (uid: string) => `${ROOTS.SALES}/sales-contracts/${uid}/shipments/add-barge`,
        },
      },
    },
    logistic: {
      root: ROOTS.LOGISTIC,
      shipments: {
        trucks: {
          list: `${ROOTS.LOGISTIC}/trucks`,
          get: (uid: string) => `${ROOTS.LOGISTIC}/trucks/${uid}`,
          create: `${ROOTS.LOGISTIC}/trucks/create`,
        },
        wagons: {
          list: `${ROOTS.LOGISTIC}/wagons`,
          get: (uid: string) => `${ROOTS.LOGISTIC}/wagons/${uid}/main-info`,
          create: `${ROOTS.LOGISTIC}/wagons/create`,
          mainInfo: {
            get: (uid: string) => `${ROOTS.LOGISTIC}/wagons/${uid}/main-info`,
          },
          shipments: {
            get: (uid: string) => `${ROOTS.LOGISTIC}/wagons/${uid}/shipments`,
          },
        },
        barges: {
          list: `${ROOTS.LOGISTIC}/barges`,
          get: (uid: string) => `${ROOTS.LOGISTIC}/barges/${uid}/main-info`,
          create: `${ROOTS.LOGISTIC}/barges/create`,
          mainInfo: {
            get: (uid: string) => `${ROOTS.LOGISTIC}/barges/${uid}/main-info`,
          },
          shipments: {
            get: (uid: string) => `${ROOTS.LOGISTIC}/barges/${uid}/shipments`,
          },
        },
      },
    },
    finance: {
      root: ROOTS.FINANCE,
      purchasePayments: {
        list: `${ROOTS.FINANCE}/purchase-payments`,
        get: (uid: string) => `${ROOTS.FINANCE}/purchase-payments/${uid}`,
        create: `${ROOTS.FINANCE}/purchase-payments/create`,
      },
    },
  },
};
