// @mui
import { enUS, ruRU } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Русский',
    value: 'ru',
    systemValue: ruRU,
    icon: 'flag:ru-4x3',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'flag:gb-4x3',
  },
];

export const defaultLang = allLangs[0]; // Russian

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
